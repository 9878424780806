import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// Alert Message
import CustomAlert from "./component/alertmessage/CustomAlert";

// PAGES
import HomePage from "./component/home/HomePage.jsx";
import AboutPage from "./component/about/AboutPage.jsx";
import ContactPage from "./component/contact/ContactPage.jsx";
import SignUp from "./forms/SignUp.jsx";
import SignIn from "./forms/SignIn.jsx";
import MainCompanyPage from "./programs/ourcompanies/MainCompanyPage.jsx";
// PROGRAMS FORMS
import InvestorForm from "./programs/investor/InvestorForm.jsx";
import FranchiseForm from "./programs/franchise/FranchiseForm.jsx";
import SEOForm from "./programs/seo/SEOForm.jsx";
import AdvertiseForm from "./programs/advertise/AdvertiseForm.jsx";
import EducationForm from "./programs/education/EducationForm.jsx";
import EcommernceForm from "./programs/ecommernce/EcommernceForm.jsx";
import MedicalNewsForm from "./programs/medicalnews/MedicalNewsForm.jsx";
import PartnerForm from "./programs/partner/ParnterForm.jsx";
import CompanyForm from "./programs/ourcompanies/CompanyForm.jsx";
import BlogAddForm from "./programs/blogs/BlogAddForm.jsx";

// PROGRAMS PAGES
import PortalPage from "./programs/mainprograms/PortalPage.jsx";
import BlogsHomePage from "./programs/blogs/BlogsHomePage.jsx";
import BlogLandingPage from "./programs/blogs/BlogLandingPage.jsx";
import Pakages from "./View/pakages/Pakages.jsx";
import PaymentGateway from "./View/paymentgateway/PaymentGateway.jsx";
import BlogPayment from "./programs/blogs/BlogPayment.jsx";

// DASHBOARD PAGES
import DashboardLayout from "./adminpanel/DashboardLayout.jsx";
import PortalForm from "./forms/PortalForm.jsx";

// LANDING PAGES
import StudentLandingPage from "./landingpage/StudentLandingPage.jsx";
import FranchisingLandingPage from "./landingpage/FranchisingLandingPage.jsx";
import AdmissionLandingPage from "./programs/admissions/AdmissionLandingPage.jsx";
import AdmissionForm from "./programs/admissions/AdmissionForm.jsx";

const AppRouter = ({
  investorPortalRef,
  franchisePortalRef,
  advertisePortalRef,
  ecommercePortalRef,
  educationPortalRef,
  blogsPortalRef,
  seoPortalRef,
  newsPortalRef,
  partnerPortalRef,
}) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("alertShown")) {
      setShowAlert(true);
    }
  }, []);

  const handleAlertClose = () => {
    setShowAlert(false);
    localStorage.setItem("alertShown", "true");
  };

  return (
    <>
      {/* {showAlert && (
        <CustomAlert
          message="We would like to thank all of our esteemed investors, partners, and franchise applicants from around the world. We are overwhelmed with your queries and trying as fast as possible to respond. Please bear with us in the meantime."
          onClose={handleAlertClose}
        />
      )} */}
      <Routes>
        {/* PAGES */}
        <Route
          path="/"
          element={
            <HomePage
              investorPortalRef={investorPortalRef}
              franchisePortalRef={franchisePortalRef}
              advertisePortalRef={advertisePortalRef}
              ecommercePortalRef={ecommercePortalRef}
              educationPortalRef={educationPortalRef}
              blogsPortalRef={blogsPortalRef}
              seoPortalRef={seoPortalRef}
              newsPortalRef={newsPortalRef}
              partnerPortalRef={partnerPortalRef}
            />
          }
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/our-companies" element={<MainCompanyPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />

        {/* PROGRAMS FORMS */}
        <Route path="/investor-portal-Form" element={<InvestorForm />} />
        <Route path="/franchise-portal-Form" element={<FranchiseForm />} />
        <Route path="/education-portal-Form" element={<EducationForm />} />
        <Route path="/seo-portal-Form" element={<SEOForm />} />
        <Route path="/ecommerce-portal-Form" element={<EcommernceForm />} />
        <Route path="/partner-portal-Form" element={<PartnerForm />} />
        <Route path="/advertise-portal-Form" element={<AdvertiseForm />} />
        <Route path="/news-portal-Form" element={<MedicalNewsForm />} />
        <Route path="/add-blog" element={<BlogAddForm />} />
        <Route
          path="/our-companies/:portalType/:countryName"
          element={<CompanyForm />}
        />

        {/* Admissions PROGRAMS PAGES */}
        <Route path="admissions-portal" element={<AdmissionLandingPage />} />
        <Route path="/admission-form/:countryName" element={<AdmissionForm />} />

        {/* PROGRAMS PAGES */}
        <Route path="/:portalType/:countryName" element={<PortalPage />} />
        <Route path="/blogs-home-page" element={<BlogsHomePage />} />
        <Route path="/pakages" element={<Pakages />} />
        <Route path="/payment-gateway" element={<PaymentGateway />} />
        <Route path="/blog-payment" element={<BlogPayment />} />
        <Route path="/blog/:title" element={<BlogLandingPage />} />

        {/* LANDING PAGES */}
        <Route path="/students-landing-page" element={<StudentLandingPage />} />
        <Route
          path="/franchising-landing-page"
          element={<FranchisingLandingPage />}
        />

        {/* Dashboard */}
        <Route path="/dashboard/*" element={<DashboardLayout />} />

        {/* Portal Form */}
        <Route path="/portal-form" element={<PortalForm />} />
      </Routes>
    </>
  );
};

export default AppRouter;
