import React from "react";
import { useParams, useLocation } from "react-router-dom";
import Flags from "react-world-flags";
import PortalForm from "../../forms/PortalForm";

const AdmissionForm = () => {
  const { countryName } = useParams(); // Extract country name from the URL
  const location = useLocation();
  const { countryCode, portalType = "admission-portal" } = location.state || {}; // Extract country code and portal type from location state

  return (
    <div>
      {countryName ? (
        <div className="pakage_investor_back" style={{ padding: "20px 0" }}>
          <div className="d-block text-center">
            {/* Country Flag */}
            <div
              className="rounded"
              style={{
                maxWidth: "300px",
                margin: "0 auto 20px",
                boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
              }}
            >
              {countryCode ? (
                <Flags
                  code={countryCode}
                  alt={`${countryName} flag`}
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              ) : (
                <p>No flag available</p>
              )}
            </div>
            <h1 className="h11_country_cscps ms-2 me-2 m-0 p-0">
              Admissions Portal
            </h1>

            <h1
              className="text-white ace_span22 ms-2 me-2 m-0 p-0"
              style={{ whiteSpace: "nowrap" }}
            >
              command & staff college of <br /> physicians & surgeons of&nbsp;
              {countryName}
            </h1>
          </div>
        </div>
      ) : (
        <p>No country selected</p>
      )}

      <h1 className="h11_country_cscps text-center mt-4 ms-2 me-2 m-0 p-0">
        Admission Form
      </h1>
      <PortalForm />
    </div>
  );
};

export default AdmissionForm;
