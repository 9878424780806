import React, { useState, useEffect } from "react";
import { ArrowBigRightDash } from "lucide-react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import Logo from "../../assets/images/logo.png";
import ProfileAvatar from "../../assets/images/girl_avatar.jpg";
import Admission from "../../assets/images/Admission-Portal2.png";
import "../../landingpage/LandingPage.css";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import CountryAdmissionDropdown from "./CountryAdmissionDropdown";

const AdmissionLandingPage = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleVideo = () => {
    const videoFrame = document.getElementById("videoIframe");
    const videoSrc = "https://www.youtube.com/embed/puH-7nhoITE";

    if (isPlaying) {
      videoFrame.src = `${videoSrc}?autoplay=0&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1&mute=0`;
    } else {
      videoFrame.src = `${videoSrc}?autoplay=1&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1&mute=0`;
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* 1. Hero Section with Background Image */}
      <div
        className="hero-section"
        style={{
          backgroundImage: `url(${Admission})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          color: "#fff",
          padding: "50px 0",
        }}
      >
        <div className="container">
          <div className="row g-0">
            <div
              className="col-12 text-center first_section_p_1"
              style={{
                fontSize: "30px",
                fontWeight: "700",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "10px",
                padding: "20px",
                display: "inline-block",
              }}
            >
              <div
                style={{
                  fontSize: "22px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: "#333",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  display: "inline-block",
                }}
              >
                <img src={Logo} alt="logo" width={100} />
              </div>
              <p
                className="first_section_p"
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#f8f9fa",
                  marginBottom: "10px",
                }}
              >
                Welcome to the
              </p>
              <p
                className="admission_section_p_1"
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                }}
              >
                Command & Staff College of Physicians & Surgeons
              </p>
              <p
                className=""
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#f8f9fa",
                  marginTop: "-2%",
                }}
              >
                Become the future medical leader as an artificial intelligence,
                medical and allied health professional. Join the
              </p>
              <h5
                className="first_section_h5 mt-4 text-uppercase fw-bold"
                style={{
                  fontSize: "22px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: "#333",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  display: "inline-block",
                }}
              >
                ARTIFICIAL INTELLIGENCE, ENVIRONMENT &amp; MEDICAL SCIENTIST
                TRAINING PROGRAM.
              </h5>
              <p
                className="first_section_p_2 mt-4"
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#f8f9fa",
                }}
              >
                This is an Artificial Intelligence integrated program combining
                environment, medicine, nursing, dentistry and pharmacy program
                with multi-tier, multi-degree programs designed for the next
                generation of global leaders for the AI Era.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 3. Custom Video */}
      <section className="video-section bg-light mt-lg-5 p-0">
        <div className="container-fluid p-0 m-0 text-center pt-3 pb-3 pb-lg-5">
          <h2 className="section_section_h2 text-capitalize text-dark mt-3">
            To fully understand, watch the complete video.
          </h2>
          <div className="row mt-3 g-0">
            <div className="col-12">
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                }}
              >
                <iframe
                  id="videoIframe"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/puH-7nhoITE?autoplay=0&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1&mute=0"
                  title="Revolutionizing Medical Education with CSCPS | Become a Leader in Medicine, AI and More"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <div
                  className="play-pause-icon"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "60px",
                    height: "60px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    zIndex: 10,
                  }}
                  onClick={toggleVideo}
                >
                  <i
                    className={`fas ${isPlaying ? "fa-pause" : "fa-play"}`}
                    style={{
                      color: "#fff",
                      fontSize: "24px",
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row g-0">
          <div className="col-12">
            <div className="text-center mt-4">
              <p className="first_section_p_1 text-white rounded-3 pt-4 pb-4 m-0">
                Become the Future Leader in Healthcare and
                <br className="d-none d-sm-block" /> Innovation at CSCPS
              </p>
              <p className="first_section_p_2 text-dark mt-4">
                "Join the revolution in medical, environmental, and
                AI-integrated education. Fast-track your career with multi-tier,
                multi-degree programs designed for the next generation of global
                leaders."
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon red">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  Fast-Track Learning
                </h5>
                <p className="card-text">
                  Skip traditional years of schooling and jump straight into
                  professional programs in Medicine, Nursing, Pharmacy, and
                  more.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon green">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  AI-Integrated Education
                </h5>
                <p className="card-text">
                  Learn in a cutting-edge curriculum powered by Artificial
                  Intelligence, preparing you for future healthcare and
                  environmental challenges.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon blue">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">Multiple Degrees</h5>
                <p className="card-text">
                  Graduate with essential degrees in Medicine, Environment, and
                  Business Administration (MBA), along with specialized AI
                  training.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon orange">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">Flexible Admission</h5>
                <p className="card-text">
                  Open to all ages and backgrounds. The only requirement is
                  English proficiency.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon pink">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  Ph.D. Opportunities
                </h5>
                <p className="card-text">
                  Our Ph.D. labs offer direct access to advanced research and
                  leadership roles, shaping the future of global healthcare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-0">
          <h2 className="section_section_h2 text-capitalize text-dark mt-3">
            How It Works:
          </h2>
          <div className="col-12">
            <ol className="m-0 ps-4">
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Select your country:
                </span>
                Choose your country to begin the process.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Fill in the form:
                </span>
                Provide the necessary details to proceed.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Wait for your turn as we are heavily loaded:
                </span>
                Our team processes applications in the order they are received.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Start Your Professional Program:
                </span>
                Our ecosystem will automatically navigate your way through the
                program.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Earn American degrees at each step:
                </span>
                Receive internationally recognized certifications and degrees.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Managed and operated by the Artificial Intelligence Board of
                  Examination:
                </span>
                Ensuring excellence and efficiency in every step.
              </li>
            </ol>
            <section className="text-center my-5">
              <h2>Select Your Country to Begin</h2>
              <div className="mt-4 p-0">
                <CountryAdmissionDropdown
                  portalType="admission-form"
                  className="p-0 m-0"
                />
              </div>
            </section>
          </div>
        </div>
      </div>

      <section className="gradient-custom">
        <div className="container my-5 py-5">
          <h2 className="section_section_h2 text-center text-capitalize text-dark mt-3">
            Reviews
          </h2>
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-10">
              <div className="text-center mb-4 pb-2">
                <i className="fas fa-quote-left fa-3x text-white"></i>
              </div>
              <div className="card">
                <div className="card-body px-4 py-3 py-lg-5">
                  {/* Carousel */}
                  <MDBCarousel showIndicators interval={5000} fade>
                    {/* Single item 1 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={1}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="John Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="John Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                John (Parent of a Medical Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "CSCPS has been a life-changing experience for
                                my daughter. The integration of AI into the
                                medical curriculum is revolutionary and ensures
                                she is learning not only traditional medical
                                skills but also preparing for the future. The
                                multi-tier program allowed her to start without
                                wasting unnecessary years in traditional
                                schooling. We couldn’t be more thrilled!"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 2 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={2}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Sarah Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Sarah Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">Sarah (Nursing Student)</h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "Joining CSCPS has been the best decision I ever
                                made. The flexibility of the program, combined
                                with its focus on cutting-edge technology like
                                AI, has prepared me for a career in modern
                                healthcare. I feel equipped to be a leader in my
                                field, and the support from faculty has been
                                outstanding. I highly recommend CSCPS for anyone
                                serious about making an impact in healthcare."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 3 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={3}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Michael Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Michael Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                Michael (Parent of a Ph.D. Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "I was initially hesitant about my son taking
                                the unconventional path through CSCPS, but the
                                personalized education system has proven its
                                worth. CSCPS allows students to work on
                                real-world research and get involved in
                                innovative Ph.D. programs right from the start.
                                The fact that they integrate business
                                administration into the curriculum is
                                forward-thinking and exactly what young
                                professionals need today."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 4 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={4}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Emily Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Emily Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                Emily (Environmental Science Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "What I love most about CSCPS is its
                                multi-disciplinary approach. I’m studying
                                environmental science but also gaining knowledge
                                in medicine and artificial intelligence, which
                                is so rare to find. This holistic education is
                                helping me look at problems in a way I never
                                imagined. CSCPS is truly revolutionizing
                                education, and I’m so proud to be part of it."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 5 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={5}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="David Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="David Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                David (Parent of a Business Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "CSCPS is not just about academics; it’s about
                                building future leaders. The focus on AI,
                                business, and healthcare is groundbreaking. My
                                son is learning how to combine these fields to
                                solve real-world problems, and the personalized
                                entry process allowed him to start right where
                                he needed to. CSCPS is preparing him to be a
                                force in the future of global business."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 6 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={6}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Julia Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Julia Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">Julia (Medical Student)</h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "The fact that CSCPS integrates artificial
                                intelligence into every aspect of their programs
                                is incredible. It’s not just about getting a
                                degree—it’s about becoming a part of a global
                                movement in education and healthcare. I feel
                                empowered to not only succeed in the medical
                                field but also be a pioneer in innovation. CSCPS
                                is setting the stage for the future!"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>
                  </MDBCarousel>
                </div>
              </div>
              <div className="text-center mt-4 pt-2">
                <i className="fas fa-quote-right fa-3x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdmissionLandingPage;
