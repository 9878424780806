import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { X } from "lucide-react";
import { Link } from "react-router-dom";
import * as images from "../../constant/images";
import { ReactTyped } from "react-typed";
import PortalSections from "../../programs/mainprograms/PortalSections";

const HomePage = ({
  investorPortalRef,
  franchisePortalRef,
  advertisePortalRef,
  seoPortalRef,
  educationPortalRef,
  ecommercePortalRef,
  newsPortalRef,
  blogsPortalRef,
  partnerPortalRef,
}) => {
  const [content, setContent] = useState("");
  const [summary, setSummary] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [color, setColor] = useState("#fe0000");

  // Data for portal sections
  const portalSectionContent = [
    {
      portalType: "admissions-portal",
      title: "ADMISSIONS PORTAL",
    },
  ];

  // Toggle color every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setColor((prevColor) => (prevColor === "#fe0000" ? "white" : "#fe0000"));
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const summarize = async () => {
    const apiUrl = "https://api.openai.com/v1/chat/completions";
    const apiKey = process.env.REACT_APP_API_KEY; // Use environment variables for security
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    };
    const data = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content:
            "You are a helpful assistant. Please summarize the text provided by the user.",
        },
        { role: "user", content },
      ],
    };
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });
      const result = await response.json();
      const summaryText =
        result.choices[0]?.message?.content || "No summary available.";
      setSummary(summaryText);
      setModalIsOpen(true);
    } catch (error) {
      console.error("Error fetching summary:", error);
    }
  };

  return (
    <>
      <section className="section section-search">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-md-9 col-12">
              <div className="text-center mt-5">
                <h1 className="text-capitalize mt-1 mt-lg-5 pt-1 pt-lg-5 h1_home_cscps">
                  Command & Staff College of
                  <br /> Physicians & Surgeons
                </h1>
                <p className="mt-3 text-muted p_home_cscps">
                  Own a permanent seat for your generations. Become a part of
                  the most powerful brand name of
                  <br className="d-none d-lg-block" /> future educational
                  institutions in medicine, artificial intelligence, and
                  environment.
                </p>
                <p className="text-muted mb-4 p_home_cscps33 fw-bold">
                  Invest in Artificial Intelligence, Environment, and Medical
                  Scientist Training Program.
                </p>
              </div>
              <div className="search-box d-flex gap-3 justify-content-center">
                <div className="form-group search-info">
                  <input
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    id="message"
                    name="message"
                    type="text"
                    className="form-control"
                    placeholder="Message ChatGPT ..."
                  />
                </div>
                <div>
                  <button
                    className="eye_btn search-btn p-2 rounded-2 ps-lg-4 pe-lg-4 ps-2 pe-2"
                    onClick={summarize}
                  >
                    Enter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid pt-5 mt-3">
        <div className="row g-0">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <img src={images.Logo} alt="ACEMAIN" width={85} />
              <h2 className="h3_home_cscps pt-3">WELCOME TO THE</h2>
              <h1 className="text-capitalize h1_home_cscps">
                Command & Staff College of
                <br /> Physicians & Surgeons
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Render Portal Sections */}
      {portalSectionContent.map((item, index) => (
        <div
          key={index}
          className="ms-1 me-1 ms-lg-4 me-lg-4 mt-5 admission_cscps_main_div"
        >
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-9 col-12">
                <div>
                  <div
                    className="m-0 p-0 pt-2 pb-2 text-center investor_cscps_h1"
                    style={{ color }}
                  >
                    <div className="reacttyped_heading">
                      <ReactTyped
                        strings={[item.title]}
                        typeSpeed={40}
                        backSpeed={20}
                        loop
                        backDelay={10000}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <Link
                        to="/admissions-portal"
                        className="text-decoration-none"
                      >
                        <button
                          className="drop_country"
                          style={{fontSize: "15px"}}
                          type="button"
                        >
                          Read More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <PortalSections
        investorPortalRef={investorPortalRef}
        franchisePortalRef={franchisePortalRef}
        advertisePortalRef={advertisePortalRef}
        seoPortalRef={seoPortalRef}
        educationPortalRef={educationPortalRef}
        newsPortalRef={newsPortalRef}
        ecommercePortalRef={ecommercePortalRef}
        blogsPortalRef={blogsPortalRef}
        partnerPortalRef={partnerPortalRef}
      />

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Summary Modal"
        ariaHideApp={false}
        style={{
          content: {
            width: "50%",
            height: "50%",
            margin: "auto",
            border: "3px solid #fe0000",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            height: "auto",
          },
        }}
      >
        <div className="modal-content">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="text-capitalize mt-4 h1_home_cscps">Summary</h1>
            </div>
            <div>
              <button
                onClick={() => setModalIsOpen(false)}
                className="eye_btn rounded"
              >
                <X />
              </button>
            </div>
          </div>
          <p className="mt-3 mb-4 text-muted p_home_cscps">{summary}</p>
          <Link to="/blogs-home-page">Go to the articles</Link>
        </div>
      </Modal>
    </>
  );
};

export default HomePage;
